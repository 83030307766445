import React, { useContext } from "react";
import styled from "styled-components";

import { Context } from "../../Router/AppRouter";
import ModalHeader from "./ModalHeader";
import TerrorFormed from "../TerrorFormed/TerrorFormed";
import makePaymentIcon from "../../assets/Group 47.png";
import autoPayIcon from "../../assets/Group 50.png";
import { Link } from "react-router-dom";

const ModalOptionStyles = styled.div`
  padding: 16px;
  display: grid;
  grid-gap: 14px;
  align-items: center;
  justify-items: center;

  .button {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    background: ${(props) => props.theme.momLightBlue};
    color: white;
    width: 300px;
    height: 50px;
    padding: 10px;
    border-radius: 3px;
    align-items: center;
    justify-items: center;
    grid-template-columns: 3fr 1fr;
    font-weight: 600;
    img {
      height: 100%;
    }
  }
`;

const values = [
  {
    type: "text",
    field: "billing_first_name",
    default: "Chris",
    label: "First Name",
    full: "true",
  },
  {
    type: "text",
    default: "Lantier",
    field: "billing_last_name",
    label: "Last Name",
    full: "true",
  },
];

const createAccountValues = [
  {
    type: "text",
    field: "email",
    default: "Chris",
    label: "Email",
    full: "true",
  },
  {
    type: "text",
    field: "phone",
    default: "Chris",
    label: "Phone",
    full: "true",
  },
  {
    type: "text",
    default: "Lantier",
    field: "project_number",
    label: "Project Number",
    full: "true",
  },

  {
    type: "text",
    default: "Lantier",
    field: "password",
    label: "Enter Password",
    full: "true",
  },
  {
    type: "text",
    default: "Lantier",
    field: "password_confirm",
    label: "Retype Password",
    full: "true",
  },
];

const ModalOptions = () => {
  const { setModalContent, setModalOpen } = useContext(Context);
  // eslint-disable-next-line
  const setSignIn = () => {
    setModalContent(
      <>
        <ModalHeader title={"Sign Into Your Account"} />
        <TerrorFormed
          values={values}
          formOptions={{
            fullPage: true,
            submitText: "Sign In",
            // headings: [
            //   { position: "left", label: "Customer Information" },
            //   { position: "left", label: "Customer Information" },
            // ],
          }}
        />
      </>
    );
  };
  // eslint-disable-next-line
  const setCreateAccount = () => {
    setModalContent(
      <>
        <ModalHeader title={"Create Your Account"} />
        <TerrorFormed
          values={createAccountValues}
          formOptions={{
            fullPage: true,
            //  submitText: "Sign In",
          }}
        />
      </>
    );
  };
  return (
    <ModalOptionStyles>
      <div className="coming-soon">Please Choose An Option Below</div>
      {/* <div role="button" onClick={() => setSignIn()} className="button payment">
        <span> Sign In </span>
        <img src={autoPayIcon} />
      </div>
      <div
        role="button"
        onClick={() => setCreateAccount()}
        className="button payment"
      >
        <span> Create Account </span>
        <img src={autoPayIcon} />

        
      </div> */}
      <Link onClick={() => setModalOpen(false)} to="/makepayment">
        <div
          role="button"
          // onClick={() => setSignIn()}
          className="button payment"
        >
          <span> Make a Payment </span>
          <img
            alt="make-payment"
            style={{ height: "50px" }}
            src={makePaymentIcon}
          />
        </div>
      </Link>
      <span> or</span>

      <Link onClick={() => setModalOpen(false)} to="/autopay">
        <div
          role="button"
          //onClick={() => setCreateAccount()}
          className="button payment"
        >
          <span> Setup Autopay </span>
          <img alt="auto-pay" style={{ height: "50px" }} src={autoPayIcon} />
        </div>
      </Link>
    </ModalOptionStyles>
  );
};

export default ModalOptions;
