import React from "react";
import useField from "../hooks/formHook";
import styled from "styled-components";

const CheckboxGrid = styled.div`
  display: grid;
  /* grid-template-columns: 50px 1fr; */
  grid-auto-flow: column;
  align-content: center;
  align-items: center;

  border: ${(props) => props.error === "yes" && `solid 3px red`};
  border-radius: 6px;
  padding: 4px;
`;

const Checkbox = ({ field, checkBoxText, error }) => {
  const { value, handleChange, formState } = useField(field);
  return (
    <CheckboxGrid error={error ? "yes" : "no"}>
      <input
        style={{ marginRight: "20px" }}
        type="checkbox"
        onChange={(e) => handleChange(e)}
        value={value}
        checked={value}
      ></input>
      <div>
        <p style={{ margin: "0", color: "rgba(139, 139, 139, 1)" }}>
          {formState && formState.amount
            ? checkBoxText.replace("$X.XX", `$${formState.amount}`)
            : checkBoxText}
        </p>
      </div>
    </CheckboxGrid>
  );
};

export default Checkbox;
