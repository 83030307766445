import React, { useState } from "react";
import useField from "../hooks/formHook";
import styled from "styled-components";

// eslint-disable-next-line
const PasswordStyles = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;

  .reveal {
    display: flex;
    width: 100px;
    justify-content: center;
    align-items: center;
  }
`;

const Password = ({ field }) => {
  const [type, setType] = useState("password");
  // eslint-disable-next-line
  const onsetType = () => {
    if (type === "password") setType("text");
    if (type === "text") setType("password");
  };
  const { value, handleChange } = useField(field);
  return (
    // <PasswordStyles>
    <>
      <input
        type={type}
        onChange={(e) => handleChange(e)}
        value={value || ""}
      ></input>
      <div className="reveal">
        {/* <i type="button" onClick={() => onsetType()} class="fas fa-eye-slash">
          OO
        </i> */}
      </div>
    </>
    // </PasswordStyles>
  );
};

export default Password;
