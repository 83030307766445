import styled from "styled-components";
import { motion } from "framer-motion";
export const ModalStyles = styled(motion.div)`
  display: flex;
  /* flex-direction: column;  */
  position: fixed;
  background: rgba(0, 0, 0, 0.74);
  top: 0;
  left: 0;
  /* transform: translate(50%, -50%); */
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  .close-button {
    width: 10%;
    padding: 10px;
    align-self: flex-end;
  }
  .modal-content {
    /* position: absolute; */
    display: grid;
    grid-template-rows: 40px 1fr;

    background: white;

    /* top: 50%;
    left: 20%; */
    /* max-width: 90%; */
    /* min-width: 20%;
    min-height: 20%;
    max-height: 50%; */
    width: 400px;

    border-radius: 4px;
    border: solid 2px ${(props) => props.theme.momLightBlue};
    /* @media (max-width: 1200px) {
      margin-left: 0;
      text-align: center;
      width: 50%;
    }
    @media (max-width: 1000px) {
      margin-left: 0;
      text-align: center;
      width: 60%;
    } */
    @media (max-width: 500px) {
      margin-left: 0;
      text-align: center;
      width: 90%;
    }
  }
`;
