import React from "react";
import PenPaper from "../../assets/Group 7.png";
import ImageAndCopy from "../../Components/ImageAndCopy";

const content = {
  img: PenPaper,
  title: "Flexible Financing Options",
  copy: [
    `
      Our mission is to provide our customers with clean energy using a plan that suits them best. 
      That’s why we offer both leasing options as well as our Power Purchase Agreement (PPA) so that you can choose
      how you pay and what you’re paying for.`,
    `Power Purchase Agreement: Pay for only the electricity that your
      panels produce monthly.`,
    ` Lease: By leasing your panels from us, you’ll be able to reap the
      benefits of solar power without fully purchasing a solar energy
      system.`,
  ],
  description: "Pen And Paper Icon",
};

const FlexibleFinancing = () => {
  return <ImageAndCopy content={content} />;
};

export default FlexibleFinancing;
