import axios from "axios";
// import moment, { now } from "moment";

export const sendEmail = (emailbody) =>
  axios.post(
    `https://veeyieqt20.execute-api.us-east-1.amazonaws.com/api/sendmailbulk`,
    emailbody
  );

export const createClientThenDebit = async (postBody) => {
  // let raw = {
  //   first_name: "Jonnathan",
  //   last_name: "Moreno",
  //   middle_initial: "M",
  //   email: "asdf@asdf.com",
  //   address: "495 Valley RD",
  //   apt_suite: "",
  //   country: "USA",
  //   state: "CA",
  //   city: "West Orange",
  //   zip_code: "07052",
  //   primary_phone: "9733805771",
  //   auto_pay: false,
  //   submit: true,
  // };
  // console.log(postBody);
  const {
    billing_first_name: first_name,
    billing_last_name: last_name,
    email,
    billing_address: address,
    billing_state: state,
    billing_zip: zip_code,
    billing_city: city,
  } = postBody;
  let newCustomer = {
    first_name,
    last_name,
    email,
    address,
    primary_phone: "1234567891",
    state,
    zip_code,
    city,
    auto_pay: false,
    submit: true,
    country: "USA",
  };

  // const checkAuthSession = (id) => {
  //   const rightnow = localStorage.getItem("now") || moment().unix();
  //   localStorage.setItem("now", rightnow);
  //   let tomorrow =
  //     moment(rightnow).add(1, "days").unix() || moment().add(1, "days").unix();

  //   console.log(rightnow - tomorrow);
  // };

  // checkAuthSession();
  //return;

  const clientid = localStorage.getItem("id");
  if (clientid) {
    let debitInfo = { ...postBody, customer_id: clientid };
    const transaction = await debitClient(debitInfo);
    if (transaction && transaction.data && transaction.data.errors) {
      return transaction.data;
    } else {
      // console.log(transaction, "trasaction");
      return transaction;
    }
  }

  const client = await createClient(newCustomer);

  if (!client) {
    return;
  }
  if (client.id) {
    const { id } = client;
    localStorage.setItem("id", id);
    let debitInfo = { ...postBody, id };
    const transaction = await debitClient(debitInfo);
    if (transaction && transaction.data && transaction.data.errors) {
      return transaction.data;
    }
  }
  if (client.data.errors) {
    return client.data;
  }
};

export const createClient = async (postBody) => {
  try {
    const res = await axios.post(
      `https://payments.momentumsolar.app/api/customers`,
      postBody
    );
    return res.data;
  } catch (e) {
    return e.response;
  }
};

export const debitClient = async (postBody) => {
  try {
    // eslint-disable-next-line
    const res = await axios.post(
      `https://payments.momentumsolar.app/api/payment-provider/debit-bank`,
      { ...postBody, country: "USA", submit: true }
    );
    // console.log(res);
  } catch (e) {
    // console.log(e.response.data);
    return e.response;
  }
};

export const oneTimeDebit = async (postBody) => {
  const {
    billing_first_name: first_name,
    billing_last_name: last_name,
    email,
    billing_address: address,
    billing_state: state,
    billing_zip: zip_code,
    billing_city: city,
  } = postBody;
  let singleDebit = {
    first_name,
    last_name,
    email,
    address,
    primary_phone: "1234567891",
    state,
    zip_code,
    city,

    submit: true,
    country: "USA",
    ...postBody,
  };

  try {
    const transaction = await axios.post(
      `https://payments.momentumsolar.app/api/payment-provider/onetime/debit-bank`,
      { ...singleDebit }
    );
    return transaction.data;
  } catch (e) {
    //  console.log(e.response.data);
    return e.response.data;
  }
};
