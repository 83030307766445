import React, { useState } from "react";
import TerrorFormed from "../Components/TerrorFormed/TerrorFormed";
// eslint-disable-next-line
import { debitClient, createClientThenDebit, oneTimeDebit } from "../Utils/API";
import SuccessfulPayment from "./SuccessfulPayment/SuccessfulPayment";
import { AnimatePresence } from "framer-motion";
//import { Context } from "../Router/AppRouter";

const stateoptions = [
  "",
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
const accountOptions = [
  { value: "", name: "" },
  { value: "checking", name: "Checking" },
  { value: "savings", name: "Savings" },
  { value: "businessChecking", name: "Business Checking" },
];

const MakePayment = () => {
  //const { auto_pay, setAutopay } = useContext(Context);
  const [errors, setErrors] = useState({});
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [error, setError] = useState("");
  // useEffect(() => {
  //   setAutopay(false);
  // }, [setAutopay]);

  const values = [
    {
      position: "left",
      type: "text",
      field: "billing_first_name",
      // default: "Chris",
      label: "First Name",
      error: errors.first_name ? errors.first_name[0] : null,
    },
    {
      position: "left",
      type: "text",
      // default: "Lantier",
      field: "billing_last_name",
      label: "Last Name",
      error: errors.last_name ? errors.last_name[0] : null,
    },
    {
      position: "left",
      type: "text",
      field: "billing_address",
      label: "Billing Address",
      // default: "26 Fleetwood Drive",
      full: "true",
      error: errors.address ? errors.address[0] : null,
    },
    {
      position: "left",
      type: "text",
      field: "email",
      label: "Email",
      // default: "chrisj17@gmail.com",
      full: "true",
      error: errors.email ? errors.email[0] : null,
    },
    {
      position: "left",
      type: "text",
      field: "confirmEmail",
      label: "Confirm Email Address",
      // default: "chrisj17@gmail.com",
      full: "true",
      error: errors.confirmEmail ? errors.confirmEmail[0] : null,
    },
    // {
    //   position: 'left', type: "select",
    //   field: "country",
    //   label: "Country",
    //   options: stateoption,
    // },
    {
      field: "billing_state",
      label: "State",
      position: "left",
      type: "select",
      full: "true",
      options: stateoptions,
      // default: "",
      error: errors.state ? errors.state[0] : null,
    },
    {
      position: "left",
      type: "text",
      field: "billing_city",
      // default: "Hazlet",
      label: "City",
      error: errors.city ? errors.city[0] : null,
    },
    {
      // default: "07730",
      position: "left",
      type: "text",
      field: "billing_zip",
      label: "Zip",
      error: errors.zip_code ? errors.zip_code[0] : null,
    },
    {
      default: false,
      position: "left",
      type: "checkbox",
      full: "true",
      field: "confirmed",
      error: errors.confirmed || "",
      checkBoxText:
        "By clicking the button below, I authorize Momentum Solar LLC, Momentum Capital LLC, or its affiliates, to charge my bank account starting on the initial bill date and on the billing date of each month following through the termination of the contract for the initial amount of $X.XX and for the subsequent scheduled amounts per my contract.",
    },
  ];

  const otherValues = [
    {
      position: "right",
      field: "name_on_account",
      label: "Name on the Account",
      // default: "chris",
      error: errors.name_on_account ? errors.name_on_account[0] : null,
    },
    {
      position: "right",
      field: "account_type",
      label: "Account Type",
      full: "true",
      // default: "",
      type: "select",

      options: accountOptions,
      error: errors.account_type ? errors.account_type[0] : null,
    },
    {
      default: "121042882",

      type: "text",
      position: "right",
      field: "routing_number",
      label: "Routing Number",
      full: "true",
      error: errors.routing_number ? errors.routing_number[0] : null,
    },
    {
      default: "123423423",
      type: "text",
      position: "right",
      field: "account_number",
      label: "Account Number",
      full: "true",
      error: errors.account_number ? errors.account_number[0] : null,
    },
    {
      // default: "100",
      type: "number",
      position: "right",
      field: "amount",
      label: "Payment Amount",
      full: "true",
      error: errors.amount ? errors.amount[0] : null,
    },
    {
      // default: "MS123",
      position: "right",
      field: "ms_number",
      label: "Contract # / Project # ",
    },
  ];

  //  const allValues =

  const clearErrors = () => {
    setTimeout(() => {
      setErrors({});
    }, 3500);
  };

  const doSomething = async (data) => {
    setError("");

    if (!data.email) {
      setErrors({
        ...errors,
        email: [`Must supply Email Address!`],
      });
      clearErrors();
      return;
    }
    if (!data.confirmEmail) {
      setErrors({
        ...errors,
        confirmEmail: [`Must supply Email Address!`],
      });
      clearErrors();
      return;
    }
    if (data.email.toLowerCase() !== data.confirmEmail.toLowerCase()) {
      setErrors({
        ...errors,
        email: [`Email Addresses Must Match!`],
        confirmEmail: [`Email Addresses Must Match!`],
      });
      clearErrors();
      return;
    }
    if (!data.confirmed) {
      console.log("check not boxed");
      setErrors({
        ...errors,
        confirmed: "Please Select the Checkbox to setup Auto-Pay",
      });
      clearErrors();
      return;
    }

    const response = await oneTimeDebit({ ...data, auto_pay: true });
    if (response && response.errors) {
      // console.log(response, "errors response");
      setErrors(response.errors);
      clearErrors();
    } else if (response && response.error) {
      console.log(response.error);
      setError(
        `There has been an error processing you Payment though you have not been charged`
      );
      clearErrors();
    } else {
      //  console.log(response, "response");
      setPaymentSuccess(true);
    }
  };
  return (
    <AnimatePresence>
      {!paymentSuccess ? (
        <>
          <TerrorFormed
            formOptions={{
              submitText: "Pay Now",
              headings: [
                { position: "left", label: "Customer Information" },
                { position: "right", label: "Payment Information" },
              ],
            }}
            values={[...values, ...otherValues]}
            onSubmit={(data) => {
              doSomething(data);
            }}
          ></TerrorFormed>
          <div style={{ padding: "10px" }} className="error">
            <span style={{ color: "red" }}> {error} </span>
          </div>
        </>
      ) : (
        <SuccessfulPayment />
      )}
    </AnimatePresence>
  );
};

export default MakePayment;
