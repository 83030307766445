import React from "react";
import "./App.css";
import AppRouter from "./Router/AppRouter";
import { createGlobalStyle, ThemeProvider } from "styled-components";

const GlobalStyle = createGlobalStyle`
 
  html {
    scroll-behavior: smooth
  }
  body {
    a {
    text-decoration: none;
    list-style: none;
  }
    font-family: ${(props) => props.theme.font};
  }
`;

const theme = {
  momLightBlue: `#299aff`,
  momGrey: "rgb(112, 112, 112)",
  formGrey: "rbg(140,140,140)",
  font: "Montserrat, sans-serif",
  lato: "Lato, sans-serif",
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <div className="App">
        <AppRouter />
      </div>
    </ThemeProvider>
  );
}

export default App;
