import React, { useState, useContext, useEffect, useRef } from "react";
import TerrorFormed from "../Components/TerrorFormed/TerrorFormed";
// eslint-disable-next-line
import { debitClient, createClientThenDebit, oneTimeDebit } from "../Utils/API";
//import SuccessfulPayment from "./SuccessfulPayment/SuccessfulPayment";
import { AnimatePresence } from "framer-motion";
import { Context } from "../Router/AppRouter";
import styled from "styled-components";
import Axios from "axios";
// import MakePaymentFrame from "./MakePaymentFrame";

const stateoptions = [
  "",
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const MakePaymentStyles = styled.div`
  display: grid;
  width: 40%;
  margin: 0 auto;

  @media (max-width: 900px) {
    width: 80%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const MakePayment = () => {
  const payRef = useRef(null);
  const { setAutopay } = useContext(Context);
  const [errors, setErrors] = useState({});
  // const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [token, setToken] = useState("");

  useEffect(() => {
    if (token) {
      console.log(payRef.current.click());
    }
  }, [token]);

  useEffect(() => {
    setAutopay(false);
  }, [setAutopay]);

  const values = [
    {
      type: "heading",
      text: "Customer Information",
      //  field: "ignore",
    },
    {
      position: "left",
      type: "text",
      field: "firstName",
      // default: "Chris",
      label: "First Name",
      error: errors.firstName,
    },
    {
      position: "left",
      type: "text",
      //default: "Lantier",
      field: "lastName",
      label: "Last Name",
      error: errors.lastName,
    },
    {
      position: "left",
      type: "text",
      field: "email",
      label: "Email",
      //default: "chrisj17@gmail.com",

      error: errors.email ? errors.email : null,
    },

    {
      //default: "100",
      type: "number",
      position: "right",
      field: "amount",
      label: "Payment Amount",

      error: errors.amount ? errors.amount : null,
    },

    {
      type: "heading",
      text: "Project Address",
    },
    {
      position: "left",
      type: "text",
      field: "address",
      label: "Billing Address",

      // default: "26 Fleetwood Drive",

      error: errors.address ? errors.address : null,
    },

    {
      position: "left",
      type: "text",
      field: "city",
      //  default: "Hazlet",
      label: "City",
      error: errors.city ? errors.city : null,
    },
    {
      field: "state",
      label: "State",
      position: "left",
      type: "select",

      options: stateoptions,
      // default: "",
      error: errors.state ? errors.state : null,
    },
    {
      // default: "07730",
      position: "left",
      type: "text",
      field: "zip",
      label: "Zip",
      error: errors.zip ? errors.zip : null,
    },
  ];

  //  const allValues =

  const clearErrors = () => {
    setTimeout(() => {
      setErrors({});
    }, 3500);
  };

  const doSomething = async (data) => {
    console.log(data);

    const filterout = Object.keys(data).filter((x) => !data[x]);

    const errs = filterout.reduce((prev, curr) => {
      prev = { ...prev, [curr]: `Please enter a value for ${curr}` };
      return prev;
    }, {});

    console.log(filterout);

    if (filterout.length > 0) {
      setErrors(errs);
      clearErrors();
      return;
    }

    const authorizeRes = await Axios.post(
      `https://frwln5r0o8.execute-api.us-east-1.amazonaws.com/latest/api/makepayment`,
      //`http://localhost:4666/api/makepayment`,
      data
      // {
      //   headers: {
      //     "content-type": "application/json; charset=utf-8",
      //     "access-control-allow-origin": "*",
      //   },
      // }
    );

    setToken(authorizeRes.data.data);
  };
  return (
    <AnimatePresence>
      <MakePaymentStyles>
        <TerrorFormed
          formOptions={{
            fullPage: true,
            submitText: "Pay Now",
            // headings: [
            //   { position: "left", label: "Customer Information" },
            //   { position: "right", label: "Payment Information" },
            // ],
          }}
          values={values}
          onSubmit={(data) => {
            doSomething(data);
          }}
        ></TerrorFormed>
        <form
          type="hidden"
          method="post"
          //action="https://secure.authorize.net/gateway/transact.dll"
          action="https://secure.authorize.net/payment/payment"
          // action="https://secure.authorize.net/gateway/transact.dll"
          id="formAuthorizeNetTestPage"
          name="formAuthorizeNetTestPage"
        >
          <input type="hidden" name="token" value={token} />

          <button style={{ display: "none" }} ref={payRef} id="btnContinue">
            {/* Continue to next page */}
          </button>
        </form>
      </MakePaymentStyles>
    </AnimatePresence>
  );
};

export default MakePayment;
