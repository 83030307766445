import React from "react";
import MomentumCap from "../../assets/Group 6.png";
import ImageAndCopy from "../../Components/ImageAndCopy";

const content = {
  img: MomentumCap,
  //title: "Easy Billing",

  copy: [
    `Momentum Capital is dedicated to delivering solutions to residential customers looking to make the switch to clean energy. We are committed to providing cleaner, affordable solar energy to our communities through several financing options to fit any budget.`,
    `We are proud to implement positive changes in the way we consume our energy resources in order to build a better future.`,
  ],
  id: "about",
};

const MomentumCapitalExplained = () => {
  return <ImageAndCopy customimgheight={"200px"} content={content} />;
};

export default MomentumCapitalExplained;
