import React, { useContext } from "react";
import styled from "styled-components";
import { Context } from "../../Router/AppRouter";
import { AnimatePresence, motion } from "framer-motion";

import OpenModalHook from "../../Utils/hooks/openmodal-hook";

const SideNavStyles = styled(motion.nav)`
  background: ${(props) => props.theme.momLightBlue};
  width: 160px;
  height: 30vh;
  position: fixed;
  right: 1vw;
  top: 100px;
  border-radius: 5px;
  

  ul {
    display: grid;
    grid-auto-flow: row;
    color: white;
    justify-self: end;
    margin: 0;
    align-items: center;
    padding: 8px;
    grid-gap: 20px;
    justify-items: center;
    font-weight: 700;
    font-size: 15px;
    /* grid-are
    a: navigate; */
  }

  a:visited {
    color: inherit;
  }
  ul > li {
    list-style: none;
  }

  .return {
    /* color: ${(props) => props.theme.momLightBlue}; */
  }
  .pay-now-button {
    background: white;
    color: ${(props) => props.theme.momLightBlue};
    padding: 10px 20px 10px 20px;
    font-weight: 700;
    font-size: 2vmin;
   
    border-radius: 4px;
    max-width: 140px;
    align-self: center;
    justify-self: center;
    border: none;
    
    /* padding-left: 20px;
    padding-right: 20px; */
  }
`;

const SideNav = () => {
  const { show } = useContext(Context);
  const { openTheModal } = OpenModalHook();
  return (
    show && (
      <AnimatePresence>
        <SideNavStyles
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={{ duration: 1 }}
        >
          <ul>
            <a href="/#home">
              <li>Home</li>
            </a>
            <li>About Us</li>
            <a href="/#contact-wrapper">
              <li>Contact</li>
            </a>
            <a href="https://www.momentumsolar.com/">
              <li className="return">Momentum Solar</li>
            </a>
            <button className="pay-now-button" onClick={() => openTheModal()}>
              Pay Now
            </button>
          </ul>
        </SideNavStyles>
      </AnimatePresence>
    )
  );
};

export default SideNav;
