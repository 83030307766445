import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
const SuccessfulPaymentStyles = styled(motion.div)`
  flex-direction: column;
  display: flex;
  align-self: center;
  justify-self: center;
  justify-content: center;
  height: 60vh;

  .complete {
    color: #336699;
    margin-bottom: 40px;
    h1 {
      font-weight: 400;
    }
  }

  button {
    margin: 0 auto;
    /* display: grid;
    grid-auto-flow: column;
    align-items: center; */
    border: none;
    font-size: 20px;
    background: ${(props) => props.theme.momLightBlue};
    color: white;
    width: 300px;
    height: 70px;
    padding: 6px;
    border-radius: 4px;
    align-items: center;
    justify-items: center;
    grid-template-columns: 2fr 1fr;
    font-weight: 600;
    color: white;

    :hover {
      cursor: pointer;
    }
  }
`;

const SuccessfulPayment = () => {
  return (
    <SuccessfulPaymentStyles
      initial={{ opacity: 0, x: -200 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 1 }}
    >
      <div className="complete">
        <h1> Your payment is complete, Thank you!</h1>
      </div>
      <Link to="/">
        <button>Return To Home</button>
      </Link>
    </SuccessfulPaymentStyles>
  );
};

export default SuccessfulPayment;
