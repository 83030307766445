import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
//import momentumlogo from "../assets/Group 2.png";
import { Context } from "../Router/AppRouter";

import Hamburger from "../Components/Hamburger/Hamburger";

//import OpenModalHook from "../Utils/hooks/openmodal-hook";

const StyledHeader = styled.header`
  display: grid;
  min-height: 70px;
  color: rgb(112, 112, 112);
  grid-auto-flow: column;
  /* border-bottom: 1px solid; */
  align-content: center;
  align-items: center;
  padding: 6px;
  position: fixed;

  width: 100%;
  background: white;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 30px;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);

  .logo {
    padding-left: 8px;
    height: 55px;
    grid-area: logo;
  }

  .hamburger {
    grid-area: ham;

    font-weight: 700;
    font-size: 4vmin;
    margin-right: 20px;
    border-radius: 4px;
    max-width: 140px;
    align-self: right;
    justify-self: end;
    /* padding-left: 20px;
    padding-right: 20px; */
  }

  /* a {
    text-decoration: none;
    list-style: none;
  } */

  @media (max-width: 1100px) {
    display: grid;
    justify-content: center;
    justify-items: center;
    grid-template-areas: "logo  ham";
  }

  ul {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 120px) 260px;
    justify-self: end;
    margin: 0;
    align-items: center;
    padding: 0;

    /* grid-are
    a: navigate; */
  }
  a {
    color: inherit;
  }

  a:visited {
    color: inherit;
  }
  ul > li {
    list-style: none;
  }

  .return {
    color: ${(props) => props.theme.momLightBlue};
  }

  .pay-now-button {
    background: ${(props) => props.theme.momLightBlue};
    color: white;
    padding: 10px 20px 10px 20px;
    font-weight: 700;
    font-size: 2vmin;
    margin-right: 20px;
    border-radius: 4px;
    max-width: 140px;
    align-self: right;
    justify-self: end;
    border: none;
    /* padding-left: 20px;
    padding-right: 20px; */
    cursor:pointer;
  }
`;

const Header = () => {
  // eslint-disable-next-line
  const { windowWidth, setModalOpen, setModalContent } = useContext(Context);
  //const { openTheModal } = OpenModalHook();

  return (
    <StyledHeader>
      <Link to="/">
        <img className="logo" src="https://momentumcapitalholdings.com/payment/assets/img/logo.png" alt="momentum-logo" />
      </Link>
      {windowWidth < 1100 ? (
        <>
          {/* <button className="pay-now-button" onClick={() => openTheModal()}>
            Sign In
          </button> */}
          <div className="hamburger">
            <Hamburger hamburgertype={"elastic"} />
          </div>
        </>
      ) : (
        <>
          <ul>
            <a href="/#home">
              <li>Home</li>
            </a>
            <li>About Us</li>
            <a href="/#contact-wrapper">
              <li>Contact</li>
            </a>
            <a href="https://www.momentumsolar.com/">
              <li className="return">Return to Momentum Solar</li>
            </a>
          </ul>
          <a href="https://momentumcapitalholdings.com/payment/" target="_self"><input 
type="button" value="Pay Now" class="pay-now-button"/></a>
         
        </>
      )}
    </StyledHeader>
  );
};

export default Header;