import React, { useContext } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ModalStyles } from "./styles";
import { Context } from "../../Router/AppRouter";

const Modal = () => {
  const { modalOpen, setModalOpen, modalContent } = useContext(Context);

  const handleModalClose = (e) => {
    if (e.target.querySelector(".modal-content")) {
      setModalOpen(false);
      return;
    } else {
      return;
    }
  };

  return (
    <AnimatePresence>
      {modalOpen && (
        <ModalStyles
          onClick={(e) => handleModalClose(e)}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4 }}
        >
          <motion.div
            initial={{ opacity: 0, y: -500 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 0 }}
            transition={{ duration: 1 }}
            className="modal-content"
          >
            {modalContent}
          </motion.div>
        </ModalStyles>
      )}
    </AnimatePresence>
  );
};

export default Modal;
