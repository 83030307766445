import { useContext } from "react";
import { FormContext } from "../TerrorFormed";

const useField = (field) => {
  const { formState, setFormState } = useContext(FormContext);

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;

    const handleValue = (value) => {
      if (typeof formState[field] === "boolean") {
        return !formState[field];
      }
      if (typeof value === "string") {
        return value;
      }
    };

    setFormState({
      ...formState,
      //errors: "",
      [field]: handleValue(value),
    });
  };

  return { value: formState[field], handleChange, formState };
};

export default useField;
