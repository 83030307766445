import React from "react";
import LighBulb from "../../assets/Group 21.png";
import ImageAndCopy from "../../Components/ImageAndCopy";

const content = {
  img: LighBulb,
  title: "Become an Expert",

  copy: [
    ` We value our customers, which is why we keep you up-to-date with your system while doing the heavy lifting for you`,
    `What you do: You control the design of your panel array and how you move forward at the end of your contract.`,
    `What we do: We design each system to cater to each client’s specifications and install each system ourselves. Once your system is installed, Momentum Capital automatically handles any maintenance or follow-up for the life of your contract. This allows you to sit back, relax and enjoy the clean energy produced by your solar array.`,
  ],
};

const Expert = () => {
  return <ImageAndCopy content={content} />;
};

export default Expert;
