import React from "react";
import styled from "styled-components";

const JustImageStyles = styled.div`
  /* border: solid 1px; */
  height: 850px;

  /* background: url(${(props) => props.imgsrc}) no-repeat;

  background-size: cover;
  object-fit: contain;

  background-position: bottom right; */

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: 50% 75%;

    z-index: -1;
  }
`;

const JustImage = ({ img }) => {
  return (
    <JustImageStyles imgsrc={img}>
      <img alt="house-with-panel" src={img} />
    </JustImageStyles>
  );
};

export default JustImage;
