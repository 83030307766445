import React, { useState, createContext } from "react";
import styled from "styled-components";

import Field from "./Field";
import { Link } from "react-router-dom";

export const FormContext = createContext({});

const SubmitDiv = styled.div`
  /* grid-area : submit; */
  grid-column: 1 / -1;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;

  a {
    display: grid;
  }

  .cancel-button {
    background-color: transparent;
    box-sizing: border-box;
    box-shadow: none;
    max-width: 100%;
    justify-self: end;

    border-radius: 4px;
    font: inherit;
    margin: 0.5em 0px;
    padding: 0.75em;
    transition: border-color 0.2s ease 0s;
    padding: 16px;
    width: 225px;

    font-size: 16px;
    border: none;
    color: #8b8b8b;
    font-weight: 600;
    /* border-style: solid 1px;
    border-color: none; */

    grid-column: ${(props) => props.full === "true" && 1 / -1};
    background: #eaeaea;
    text-align: center;

    :hover {
      cursor: pointer;
    }
  }

  .submit-button {
    background-color: transparent;
    box-sizing: border-box;
    box-shadow: none;
    max-width: 100%;

    border-radius: 4px;
    font: inherit;
    margin: 0.5em 0px;
    padding: 0.75em;
    transition: border-color 0.2s ease 0s;
    padding: 16px;
    width: 225px;

    font-size: 16px;
    font-weight: 600;
    /* border-style: solid 1px;
    border-color: rgba(139, 139, 139, 0.2); */
    border: none;

    grid-column: ${(props) => props.full === "true" && 1 / -1};
    background: ${(props) => props.theme.momLightBlue};
    color: white;
    :hover {
      cursor: pointer;
    }
  }
`;

const MakePaymentStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 92%;
  margin: 10px auto;
  grid-gap: 16px;

  @media (max-width: 900px) {
    margin: 0 auto;
    grid-template-areas:
      "left"
      "right";

    .left {
      grid-area: left;
    }

    .right {
      grid-area: right;
    }
  }

  @media (max-width: 600px) {
    margin: 0;
    grid-gap: 0px;
  }
  h1 {
    text-align: left;
    margin-left: 6px;
    font-size: 28px;
  }
`;

const TerrorFormedStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: baseline;
  grid-auto-flow: row;
  grid-gap: 10px;
  margin: 4px;

  .heading {
    color: ${(props) => props.theme.formGrey};
    color: rgb(140, 140, 140);
    display: grid;
    grid-column: 1/-1;
  }

  @media (max-width: 600px) {
    margin-top: 12px;
    grid-gap: 4px;
  }

  color: ${(props) => props.theme.formGrey};
`;

const InputAndLabel = styled.div`
  display: grid;
  grid-column: ${(props) => props.full === "true" && `1 / -1`};
  text-align: left;
  /* grid-row-gap: 8px; */

  input,
  select,
  textarea {
    background-color: transparent;
    box-sizing: border-box;
    box-shadow: none;
    max-width: 100%;
    border-width: 1.5px;
    border-style: solid;
    border-image: initial;

    border-radius: 3px;
    font: inherit;
    margin: 0.5em 0px;
    padding: 0.75em;
    transition: border-color 0.2s ease 0s;
    padding: 14px;
    border-radius: 8px;
    font-size: 18px;
    border-style: solid;
    border-color: ${(props) =>
      props.error === "yes" ? "red" : "rgba(139, 139, 139, 0.25);"};
    color: rgba(139, 139, 139, 0.9);

    grid-column: ${(props) => props.full === "true" && 1 / -1};
  }
  select {
    text-indent: 5px;
    -webkit-appearance: none;
    padding-right: 30px;
    background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E),
      linear-gradient(transparent 0%, transparent 100%);
    background-size: 0.65em, 100%;
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0px 0px;
  }

  option {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding-right: 30px;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
      linear-gradient(transparent 0%, transparent 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    &::-ms-expand {
      display: none;
    }
  }

  #submit {
    grid-column: 1/ -1;
  }

  h3 {
    margin: 0;
    margin-left: 6px;
    color: ${(props) => props.theme.formGrey};
    color: #8c8c8c;
    font-weight: 500;
  }
`;

const TerrorFormed = ({ values, onSubmit, formOptions }) => {
  const loadedFormOptions = {
    fullPage: formOptions ? formOptions.fullPage : false,
    submitText: formOptions ? formOptions.submitText : "Submit",
    headings: formOptions ? formOptions.headings : [],
  };

  const { fullPage, submitText, headings } = loadedFormOptions;
  //  console.log(values.map((item) =>  Object.values(item)));
  const initialState = values.reduce((prev, curr) => {
    if (!curr.field) return prev;
    prev = {
      ...prev,
      [curr["field"]]: curr.default && curr.default,
    };
    return prev;
  }, {});

  const [formState, setFormState] = useState(initialState);

  const onSubmitForm = (e) => {
    e.preventDefault();

    onSubmit(formState);
  };

  return (
    <FormContext.Provider
      value={{
        formState,
        setFormState,
      }}
    >
      {fullPage ? (
        <form onSubmit={(e) => onSubmitForm(e)}>
          <TerrorFormedStyles className="left">
            {/* {Object.keys(values).map((value) => <input> </input>)} */}
            {values.map(
              ({
                field,
                label,
                full,
                type,
                options,
                error,
                checkBoxText,
                text,
              }) =>
                type === "heading" ? (
                  <div key={text} className="heading">
                    <h1> {text}</h1>
                  </div>
                ) : (
                  <InputAndLabel
                    error={error ? "yes" : "no"}
                    key={field}
                    full={full}
                  >
                    <h3> {label} </h3>
                    <Field
                      checkBoxText={checkBoxText}
                      options={options}
                      field={field}
                      type={type}
                    />

                    {error}
                  </InputAndLabel>
                )
            )}
            <SubmitDiv>
              <Link to="/">
                <input
                  readOnly
                  className="cancel-button"
                  id="submit"
                  type="cancel"
                  value="Cancel"
                />
              </Link>
              <input
                className="submit-button"
                id="submit"
                type="submit"
                value={submitText ? submitText : "Submit"}
              />
            </SubmitDiv>
          </TerrorFormedStyles>
        </form>
      ) : (
        <form onSubmit={(e) => onSubmitForm(e)}>
          <MakePaymentStyles>
            <TerrorFormedStyles className="left">
              <h1
                style={{
                  gridColumn: "1/ -1",
                  color: "rgba(139, 139, 139, 0.9)",
                  fontWeight: 500,
                }}
              >
                {headings && headings[0].label}
              </h1>
              {/* {Object.keys(values).map((value) => <input> </input>)} */}
              {values
                .filter(({ position }) => position === "left")
                .map(
                  ({
                    field,
                    label,
                    full,
                    type,
                    options,
                    error,
                    checkBoxText,
                  }) => (
                    <InputAndLabel
                      error={error ? "yes" : "no"}
                      key={field}
                      full={full}
                    >
                      <h3> {label} </h3>
                      <Field
                        checkBoxText={checkBoxText}
                        options={options}
                        field={field}
                        type={type}
                        error={error}
                      />
                      <div style={{ marginTop: "10px" }} className="err">
                        {error}
                      </div>
                    </InputAndLabel>
                  )
                )}
            </TerrorFormedStyles>
            <TerrorFormedStyles className="right">
              <h1
                style={{
                  gridColumn: "1/ -1",
                  color: "rgba(139, 139, 139, 0.9)",
                  fontWeight: 500,
                }}
              >
                {headings && headings[1].label}
              </h1>
              {/* {Object.keys(values).map((value) => <input> </input>)} */}
              {values
                .filter(({ position }) => position === "right")
                .map(
                  ({
                    field,
                    label,
                    full,
                    type,
                    options,
                    error,
                    checkBoxText,
                  }) => (
                    <InputAndLabel
                      error={error ? "yes" : "no"}
                      key={field}
                      full={full}
                    >
                      <h3> {label} </h3>
                      <Field options={options} field={field} type={type} />
                      {error}
                    </InputAndLabel>
                  )
                )}
            </TerrorFormedStyles>
            <SubmitDiv>
              <Link to="/">
                <input
                  readOnly
                  className="cancel-button"
                  id="submit"
                  type="cancel"
                  value="Cancel"
                />
              </Link>
              <input
                className="submit-button"
                id="submit"
                type="submit"
                value="Pay Now"
              />
            </SubmitDiv>
          </MakePaymentStyles>
        </form>
      )}
    </FormContext.Provider>
  );
};

export default TerrorFormed;
