import React from "react";

import styled from "styled-components";

const ImageAndCopyStyles = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  /* min-height: 870px;
  grid-template-rows: 120px 1fr 120px; */
  min-height: 640px;
  grid-template-rows: 105px 1fr 105px;

  .title {
    color: #299aff;
  }

  img {
    height: ${(props) =>
      props.customimgheight ? props.customimgheight : "100px"};
  }

  .copy {
    display: grid;
    width: 90%;
    margin: auto;
    grid-row-gap: 24px;
    font-family: "Lato", sans-serif;
    grid-gap: 30px;

    p {
      margin: 0;
      color: #8b8b8b;
      font-weight: 400;
    }
  }
  .title {
    font-weight: 400;
  }

  .center {
    display: grid;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
`;

const ImageAndCopy = ({ content, customimgheight }) => {
  const { img, title, copy, description, id } = content;
  return (
    <ImageAndCopyStyles
      id={id}
      role="navigation"
      customimgheight={customimgheight}
    >
      <div></div>
      <div className="center">
        <img alt={description} src={img} />
        <h2 className="title"> {title} </h2>
        <div className="copy">
          {copy.map((text) => (
            <p key={text}> {text}</p>
          ))}
        </div>
      </div>
      <div></div>
    </ImageAndCopyStyles>
  );
};

export default ImageAndCopy;
