import React from "react";
import useField from "../hooks/formHook";

const Select = ({ field, options }) => {
  const { value, handleChange } = useField(field);
  return (
    <>
      <select onChange={(e) => handleChange(e)} value={value}>
        {options.map((option) =>
          typeof option === "object" ? (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ) : (
            <option key={option} value={option}>
              {option}
            </option>
          )
        )}
      </select>
    </>
  );
};

export default Select;
