import React from "react";
import Bill from "../../assets/Group 26.png";
import ImageAndCopy from "../../Components/ImageAndCopy";

const content = {
  img: Bill,
  title: "Easy Billing",

  copy: [
    `When taking care of your monthly payments, you will receive a bill from your utility company and one from Momentum Capital.`,
    `Utility Bill: This bill will include charges for any power used from your neighborhood’s grid. You will see significant savings because you’ll be running less on this resource and more on clean power from the sun!`,
    `Momentum Capital: This bill will include charges for your financing plan of choice, whether that is a Power Purchase Agreement or lease.`,
  ],
};

const EasyBilling = () => {
  return <ImageAndCopy content={content} />;
};

export default EasyBilling;
