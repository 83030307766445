import React, { useState, useEffect, createContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "../Pages/Home";
import Header from "../Components/Header";
import MakePayment from "../Pages/MakePayment";
import Modal from "../Components/Modal/Modal";
import SideNav from "../Components/SideNav/SideNav";
import SetupAutoPay from "../Pages/SetupAutopay";

export const Context = createContext({});

const AppRouter = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [show, setShow] = useState(false);
  const [auto_pay, setAutopay] = useState(false);
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <>
      <Context.Provider
        value={{
          windowWidth,
          modalOpen,
          setModalOpen,
          modalContent,
          setModalContent,
          show,
          setShow,
          auto_pay,
          setAutopay,
        }}
      >
        <Router>
          <Modal />
          <Header />
          <div className="app-container">
            <SideNav />
            <Switch>
              <Route exact={true} path="/">
                <Home />
              </Route>
              <Route exact path="/makepayment">
                <MakePayment />
              </Route>
              <Route exact path="/autopay">
                <SetupAutoPay />
              </Route>
            </Switch>
          </div>
        </Router>
      </Context.Provider>
    </>
  );
};

export default AppRouter;
