import React, { useState } from "react";
import styled from "styled-components";
import twitterlogo from "../../assets/Twitter.png";
import fblogo from "../../assets/FB.png";
import linkedin from "../../assets/LinkedIn.png";
import instalogo from "../../assets/IG-1.png";
import momentumlogo from "../../assets/Path 22.png";
import { sendEmail } from "../../Utils/API";

const ContactUsStyles = styled.div`
  display: grid;
  background: #299aff;
  /* height: 500px; */
  display: grid;

  align-items: center;
  grid-template-rows: 70px 1fr auto;
  grid-row-gap: 30px;

  .contact-wrapper {
    display: grid;
    grid-template-areas: "contact-info  contact-form";
    align-items: center;

    @media (max-width: 900px) {
      justify-content: center;
      justify-items: center;
      grid-template-areas:
        "contact-info"
        "contact-form";
      grid-template-columns: 1fr;
    }
  }

  h4 {
    margin: 0;
    color: white;
    font-weight: 600;
    text-align: left;
  }

  .contact-info {
    display: grid;
    text-align: left;
    margin-left: 60px;
    @media (max-width: 768px) {
      margin-left: 0;
      text-align: center;
    }
    grid-template-rows: 30px 30px 30px;
    grid-row-gap: 10px;
    grid-area: contact-info;

    /* align-items: flex-start; */
    a {
      margin: 0;
      text-decoration: none;
    }
    h3 {
      margin: 0;
      color: white;
      font-weight: 500;
      font-size: calc(2vmin + 10px);
    }

    h4 {
      margin: 0;
      color: white;
      font-weight: 500;
      font-size: calc(1vmin + 8px);
    }
  }

  .contact-form-area {
    display: grid;
    grid-row-gap: 30px;
    grid-area: contact-form;
    grid-auto-flow: row;
    width: 90%;
  }

  .contact-form {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 10px;
  }

  .footer-copy {
    color: white;
    font-size: 12px;
    width: 94%;
    margin: 0 auto;
    padding-bottom: 16px;
  }

  ul {
    list-style: none;
  }

  .logo-area {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: ". logos .";
  }
  .footer-logos {
    grid-area: logos;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: flex-end;
    padding: 0;
    grid-gap: 20px;
    margin-bottom: 12px;
  }
`;

export const StyledInput = styled.input`
  padding: 14px;
  border-radius: 5px;
  font-size: 18px;
  border-style: solid;
  border-color: ${(props) =>
    props.color ? props.theme[props.color] : "white"};

  grid-column: ${(props) => props.full === "true" && 1 / -1};
`;
const StyledSubmit = styled.input`
  padding: 14px;
  border-radius: 5px;
  font-size: 18px;
  border-style: solid;

  border: none;
  background: white;

  color: ${(props) => props.theme.momLightBlue};
  :hover {
    cursor: pointer;
  }
`;
const initialState = {
  name: "",
  email: "",
  phone: "",
  comments: "",
};

const ContactUs = () => {
  const [message, setMessage] = useState("");
  const [state, setState] = useState(initialState);

  const submitForm = async (e) => {
    e.preventDefault();
    const res = await sendEmail({
      from: `capitalfeedback@momentumsolar.com`,
      to: ["clantier@momentumsolar.com"],
      subject: "Momentum Capital User Comment",
      body: `
      <div style={{marginBottom: 20px }}>
        Hello,
        <br/>
        ${state.comments}
     </div>
     <br/>
     <br/>
       <div>
        ${state.name}
        <br>
        ${state.email}
        <br>
        ${state.zip}
       </div>
      `,
    });
    if (res.data.success) {
      setMessage("Your Message Was Sucessfully Submitted");
      setState(initialState);
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  };

  const captialize = (words) =>
    words
      .split(" ")
      .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
      .join(" ");

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;

    setState({
      ...state,
      [name]: value,
    });
  };

  const logos = [
    {
      description: "twitter-logo",
      img: twitterlogo,
      link: "https://twitter.com/momentumsolar",
    },
    {
      description: "instagram-logo",
      img: instalogo,
      link: "https://www.instagram.com/momentumsolar/",
    },
    {
      description: "momentum-solar-logo",
      img: momentumlogo,
      link: "https://twitter.com/momentumsolar",
    },
    {
      description: "fb-logo",
      img: fblogo,
      link: "https://www.facebook.com/momentumsolar/",
    },
    {
      description: "linkedin-logo",
      img: linkedin,
      link: "https://twitter.com/momentumsolar",
    },
  ];

  return (
    <ContactUsStyles>
      <div></div>
      <div id="contact-wrapper" className="contact-wrapper">
        <div className="contact-info">
          <h3>Contact Us</h3>
          <a href="tel:7323876955">
            <h3> (732) 387-6955</h3>
          </a>
          <a href="mailto:CUSTOMERSUPPORT@MOMENTUMCAPITALHOLDINGS.COM">
            <h4> CUSTOMERSUPPORT@MOMENTUMCAPITALHOLDINGS.COM</h4>
          </a>
        </div>
        <div className="contact-form-area">
          <div className="contact-form-title">
            <h4> Submit A Comment </h4>
          </div>
          <form onSubmit={(e) => submitForm(e)} className="contact-form">
            {Object.keys(state).map((key) => {
              return (
                <StyledInput
                  key={key}
                  onChange={(e) => handleChange(e)}
                  name={key}
                  value={state[key]}
                  placeholder={captialize(key)}
                />
              );
            })}
            {/* <StyledInput
              onChange={(e) => handleChange(e)}
              value={state.name}
              placeholder="Email"
            />
            <StyledInput
              onChange={(e) => handleChange(e)}
              value={state.email}
              placeholder="Name"
            />
            <StyledInput
              onChange={(e) => handleChange(e)}
              value={state.phone}
              placeholder="Phone"
            />
            <StyledInput
              onChange={(e) => handleChange(e)}
              value={state.zip}
              placeholder="Zip" */}

            <StyledSubmit
              type="submit"
              value={`${message ? message : "Send"} `}
            />
          </form>
        </div>
      </div>
      <footer>
        <div className="logo-area">
          <ul className="footer-logos">
            {logos.map(({ img, link, description }) => (
              <a key={description} href={link}>
                <li>
                  <img alt={description} src={img} />
                </li>
              </a>
            ))}
          </ul>
        </div>
        <div className="footer-copy">
          © 2019 Momentum Solar. All Rights Reserved by Pro Custom Solar LLC
          D|B|A Momentum Solar Subject to applicable terms and conditions.
          Eligibility and savings is determined by municipal utility, roof
          condition and roof space, azimuth, tree location, shading and other
          factors. © Pro Custom Solar LLC D|B|A Momentum Solar 2017. HIC # – NJ:
          13V0553900; NYC: 2042828-DC; Suffolk CTY, NY: 57578-H; Yonkers, NY:
          6632; Nassau CTY: H2410050000; CA: 1026366; FL: CVC57036.
        </div>
      </footer>
    </ContactUsStyles>
  );
};

export default ContactUs;
