import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import landingImg from "../../assets/BG.png";
import makePaymentIcon from "../../assets/Group 47.png";
import autoPayIcon from "../../assets/Group 50.png";

const LandingStyles = styled.div`
  display: grid;
  height: 880px;
  grid-template-rows: 200px 2fr 1fr;
  background: url(${landingImg}) no-repeat center center fixed;

  background-size: cover;
  object-fit: contain;
  align-items: flex-start;
  align-content: flex-start;
  grid-row-gap: 10px;
  /* background-position: bottom right; */
  h2 {
    margin: 0;
  }
  p {
    margin: 0;
  }

  .middle {
    display: grid;
    align-content: flex-start;
    justify-items: start;
    grid-row-gap: 16px;
    margin-left: 30px;
  }

  .button {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    background: ${(props) => props.theme.momLightBlue};
    color: white;
    width: 300px;
    height: 70px;
    padding: 6px;
    border-radius: 3px;
    align-items: center;
    justify-items: center;
    grid-template-columns: 2fr 1fr;
    img {
      height: 100%;
    }
  }

  .text-area {
    color: white;
    display: grid;
    grid-template-columns: minmax(370px, 370px);
    text-align: left;
    grid-row-gap: 10px;
    font-family: "Lato", sans-serif;
  }
`;

const Landing = () => {
  return (
    <LandingStyles>
      <div id="home" className="top"></div>
      <div className="middle">
        <div className="text-area">
          <h2> SOLAR WITHIN REACH</h2>
          <p>
            Solar energy has plenty of environmental and financial benefits
            attainable for homeowners of a wide range of backgrounds. With
            electricity bills on the rise, solar panels can give you the price
            protection and security that you’re looking for.
          </p>
        </div>
        <a href="https://momentumcapitalholdings.com/payment/">
          <div role="button" className="button payment">
            <span> Make A Payment </span>
            <img alt="make-payment" src={makePaymentIcon} />
          </div>
        </a>
        <a href="https://momentumcapitalholdings.com/payment/autopay-index.php">
          <div role="button" className="button payment">
            <span> Setup Autopay </span>
            <img alt="auto-pay" src={autoPayIcon} />
          </div>
          <Link to="/autopay"></Link>
        </a>
      </div>
      <div className="bottom"></div>
    </LandingStyles>
  );
};

export default Landing;
