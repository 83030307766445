import React from "react";
import Landing from "./SubPages/Landing";
import FlexibleFinancing from "./SubPages/FlexibleFinancing";
import JustImage from "../Components/JustImage";
import Install from "../assets/asset-1.svg";

import HouseOne from "../assets/asset-2.svg";
import ContactUs from "./SubPages/ContactUs";
import Expert from "./SubPages/Expert";
import EasyBilling from "./SubPages/EasyBilling";
import MomentumCapitalExplained from "./SubPages/MomentumCapitalExplained";

const Home = () => {
  return (
    <>
      <Landing />
      <FlexibleFinancing />
      <JustImage img={HouseOne} />
      <Expert />
      <JustImage img={Install} />
      <EasyBilling />
      <MomentumCapitalExplained />
      <ContactUs />
    </>
  );
};

export default Home;
