import React from "react";
import useField from "../hooks/formHook";
const Text = ({ field }) => {
  const { value, handleChange } = useField(field);

  return (
    <input
      autoComplete="on"
      type="text"
      onChange={(e) => handleChange(e)}
      value={value || ""}
    ></input>
  );
};

export default Text;
