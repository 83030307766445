import React, { useContext } from "react";
import ModalHeader from "../../Components/Modal/ModalHeader";
import ModalOptions from "../../Components/Modal/ModalOptions";

import { Context } from "../../Router/AppRouter";

const OpenModalHook = () => {
  const { setModalOpen, setModalContent, setShow } = useContext(Context);
  const openTheModal = () => {
    setShow(false);
    setModalOpen(true);
    setModalContent(
      <>
        <ModalHeader title={"Choose An Option Below"} />
        <ModalOptions />
      </>
    );
  };
  return { openTheModal };
};

export default OpenModalHook;
